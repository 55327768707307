.categories-tabs {
  display: flex;
  justify-content: space-around;
  background-color: #F2F2F0;
  padding: 10px;
}

.category-tab {
  width: 100px;
  text-align: center;
  padding: 10px;
  background-color: #e0e0e0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 1px solid #ccc;
  font-size: 15px;
}

.category-tab:hover {
  background-color: #E1BA9C;
  color: white;
}

.category-tab.active {
  background-color: #CD906A;
  color: white;
}
