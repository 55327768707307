.jsb-footer {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 99;
  box-sizing: inherit;
  background: #935337;
}

.footer {
  position: relative;
  border-bottom-style: none;
  background: none;
  display: block;
  border-bottom-width: 0px;
  margin-left: auto;
  margin-right: auto;
}

.footer-centralized-div {
  min-height: 120px;
  max-width: 1240px;
  display: grid;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
}

.footer-row-div {
  display: grid;
  align-items: center;
  grid-column-gap: 20px;
  overflow-wrap: anywhere;
  margin: 0%;
  color: #fff;
}

.contact {
  font-size: x-small;
}