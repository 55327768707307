@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.App {
  text-align: center;
  background: #F2F2F0;
  font-family: 'Poppins', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.banner {
  margin-top: 100px;
  height: 80vh;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel {
  display: flex;
  transition: transform 1s ease-in-out; /* Smooth slide transition */
  height: 100%;
  width: 100%;
}

.carousel-slide {
  flex: 0 0 100%; /* Each slide takes 100% of the container width */
  position: relative;
}

.carousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.booking-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
}

/* Dots */
.dots {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.dot {
  width: 15px;
  height: 15px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #007BFF;
}

.float-button {
  position: fixed; /* Makes it stick to the viewport */
  bottom: 20px; /* Distance from the bottom of the viewport */
  right: 20px; /* Distance from the right of the viewport */
  width: 60px; /* Size of the button */
  height: 60px;
  background-color: #CD906A; /* Button background color */
  color: white; /* Button text/icon color */
  border: none; /* Remove default border */
  border-radius: 50%; /* Makes it circular */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Add a shadow for a floating effect */
  font-size: 24px; /* Font size for the "+" icon */
  cursor: pointer; /* Pointer cursor on hover */
  z-index: 1000; /* Ensures it hovers above other elements */
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.float-button:hover {
  background-color: #e64a19; /* Change color on hover */
  transform: scale(1.1); /* Slightly enlarge the button on hover */
}