.services-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.content-area {
  display: flex;
  margin-top: 20px;
  width: 100%;
  height: auto;
  max-height: 400px;
}

.services-list {
  width: 20%;
  height: auto;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}

.service-item {
  padding: 15px;
  margin-bottom: 10px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  /* font-size: 15px; */
}

.service-item:hover {
  background-color: #E1BA9C;
}

.service-item.active {
  background-color: #E1BA9C;
}

.service-preview {
  width: 80%;
  position: relative;
  background: white;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 400px;
  overflow-y: auto; /* Enable scrolling */
  margin-right: 0.5em;
}

#preview-info {
  color: #555;
}

.service-preview img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* Responsive styles */
@media (max-width: 768px) {
  .service-item {
    font-size: x-small;
  }
}