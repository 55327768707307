/* General styles for header */
.jsb-header {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 99;
  box-sizing: inherit;
  background: #935337;
  position: fixed;
  top: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header {
  position: relative;
  background: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

a {
  text-decoration: none;
  color: white;
}

a:visited {
  color: white;
}

.header-centralized-div {
  min-height: 120px;
  max-width: 1240px;
  display: grid;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.header-row-div {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-column-gap: 20px;
}

.header-left-col-div {
  text-align: left;
}

.header-left-col-div img {
  max-height: 100%;
  max-width: 16em;
  object-fit: contain;
  text-align: left;
}

.header-right-col-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

/* Hamburger menu styles */
.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.hamburger-menu span {
  width: 25px;
  height: 3px;
  background: white;
  transition: 0.3s ease;
}

/* Hide menu by default */
.menu-div {
  display: flex;
  justify-content: flex-end;
}

.menu-open {
  display: block !important;
}

.horizontal-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.horizontal-menu li {
  margin-right: 20px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.horizontal-menu li:hover {
  color: #007BFF;
  font-weight: bold;
}

/* Responsive styles */
@media (max-width: 768px) {
  .header-right-col-div {
    flex-direction: column;
  }

  .hamburger-menu {
    display: flex;
  }

  .menu-div {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background: #935337;
    padding: 10px;
    border-radius: 5px;
  }

  .horizontal-menu {
    flex-direction: column;
  }

  .horizontal-menu li {
    margin: 10px 0;
  }
}
