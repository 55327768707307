.main {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.booking-policies {
  max-width: 1200px;
  /* padding: 20px; */
}

.booking-policies h1 {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 20px;
  letter-spacing: 2px;
}

.policies-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.policy-box {
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.policy-box img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.policy-box h2 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.policy-box p {
  font-size: 0.95em;
  line-height: 1.5;
}