.services-grid {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 20px;
    padding: 20px;
}

.service-card {
    justify-content: space-between; /* Spread content across the row */
    align-items: center;
    background: white;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.service-card h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.service-card p {
    font-size: 14px;
    color: #666;
    margin: 5px 0;
}

.service-card .description {
    flex-grow: 1;
    margin-right: 20px; /* Space between text and button */
}

.book {
    background-color: #e0e0e0;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
}

.book:hover {
    background-color: #E1BA9C;
}

/* Title section */
.service-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
    color: #333;
}

/* Info section: Flexbox for duration/price and description */
.service-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
}

/* Responsiveness: Single column layout */
@media (max-width: 768px) {
.service-info {
    flex-direction: column; /* Stack all elements vertically */
    gap: 10px; /* Add spacing between elements */
    }

    .service-description {
    text-align: left;
    }
    .book {
    width: 100%; /* Full-width button */
    text-align: center;
    }
}